<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant required type="none" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 교육년도 -->
          <c-datepicker
            required
            name="search"
            type="year"
            label="LBL0002847"
            default="today"
            v-model="searchParam.educationYear"
          ></c-datepicker>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            codeGroupCd="EDU_KIND_FST_CD"
            type="none"
            default="first"
            itemText="codeName"
            itemValue="code"
            name="educationKindCdLarge"
            label="LBL0002933"
            v-model="searchParam.educationKindCdLarge"
            @setCodeData="getList"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1" style="margin-top:14px">
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList(false)" />
        </div> 

      </template>
    </c-search-box>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <div class="card cardcontents">
        <div class="card-body">
          <apexchart 
            ref="eduChart" 
            height="300px" 
            type="bar"
            :width="eduChart.chartWidth"
            :options="eduChart.chartOptions" 
            :series="eduChart.series"
            @dataPointSelection="selectedBar"></apexchart>
        </div>
      </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <div class="card cardcontents">
        <div class="card-body">
          <apexchart 
            ref="eduChartDetail" 
            height="350px" 
            type="bar"
            :width="eduChartDetail.chartWidth"
            :options="eduChartDetail.chartOptions" 
            :series="eduChartDetail.series"></apexchart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import selectConfig from '@/js/selectConfig';
export default {
  name: 'annual-education-trend-analysis-graph',
  components: { 
    apexchart: VueApexCharts
  },
  props: {
  },
  data() {
    return {
      searchParam: {
        plantCd: null,
        educationYear: '',
        educationKindCdLarge: null,
      },
      searchYear: '',
      editable: true,
      listUrl: '',
      detailUrl: '',
      rate: [],
      detailRate: [],
      eduChart: {
        series: [
          {
            id: 'checkCompleteCnt',
            name: '이수',
            data: [],
          },
          {
            id: 'checkCompleteCntNone',
            name: '미이수',
            data: [],
          },
        ],
        chartOptions: {
          annotations: { 
            xaxis: [],
          },
          chart: {
            width: '100%',
            type: 'bar',
            stacked: true,
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
              endingShape: 'rounded'
            },
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          dataLabels: {
            enabled: true,
          },
          colors: [
            '#00BCD4', '#F44336', 
          ],
          stroke: {
            show: true,
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
            title: {
              text: undefined
            },
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val + " 명"
              }
            }
          },
          title: {
            text: '교육과정별 이수/미이수 인원 현황',
            offsetX: 15
          },
          subtitle: {
            text: '[Bar를 클릭시 월별 이수/미이수 인원 현황을 볼 수 있습니다.]',
            offsetX: 15
          },
          fill: {
            opacity: 1,
          },
          legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 10
          },
        },
        
        seriesQuarter: [{
          data: []
        }],
        
      },
      eduChartDetail: {
        series: [
          {
            id: 'checkCompleteCnt',
            name: '이수',
            data: [],
          },
          {
            id: 'checkCompleteCntNone',
            name: '미이수',
            data: [],
          },
        ],
        chartOptions: {
          annotations: { 
            xaxis: [],
          },
          chart: {
            width: '100%',
            type: 'bar',
            stacked: true,
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
              endingShape: 'rounded'
            },
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          dataLabels: {
            enabled: true,
          },
          colors: [
            '#00BCD4', '#F44336', 
          ],
          stroke: {
            show: true,
          },
          xaxis: {
            categories: [
              
            ],
          },
          yaxis: {
            title: {
              text: undefined
            },
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val + " 명"
              }
            }
          },
          title: {
            text: '월별 교육 이수/미이수 현황',
            offsetX: 15
          },
          fill: {
            opacity: 1,
          },
          legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 10
          },
        },
        
        seriesQuarter: [{
          data: []
        }],
        
      },
      copyYear: '',
    };
  },
  computed: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.edu.annual.trendgraph.list.url;
      this.detailUrl = selectConfig.sop.edu.annual.trendgraph.get.url;
      // this.getList();
    },
    getList() {
      if (!this.searchParam.educationYear) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSG0000857', // 교육년도를 선택하세요.
          type: 'warning', // success / info / warning / error
        });
        return;
      } 
      // else if (!this.searchParam.educationKindCdLarge) {
      //   window.getApp.$emit('ALERT', {
      //   title: 'LBLGUIDE', // 안내
      //     message: 'MSG0000869', // 교육종류를 선택하세요.
      //     type: 'warning', // success / info / warning / error
      //   });
      //   return;
      // }
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;

      // 월별 현황 초기화
      this.$_.forEach(this.eduChartDetail.series, item => {
        item.data = ['','','','','','','','','','','',''];
      })
      this.eduChartDetail.chartOptions.annotations.xaxis = [];
      this.eduChartDetail.chartWidth = '100%';
      this.$refs['eduChartDetail'].refresh();

      this.rate = [];
      this.$http.request((_result) => {
        this.copyYear = this.$_.cloneDeep(this.searchParam.educationYear)
        let taskIdx = 0;
        this.$_.forEach(_result.data, task => {
          this.$_.forEachRight(this.eduChart.series, item => {
            if (!this.rate[taskIdx] && task[item.id] > 0) {
              this.rate[taskIdx] = task.checkCompleteTotal > 0 ?
                String(this.$_.round((task.checkCompleteCnt / task.checkCompleteTotal) * 100)) + '%' : '0%';
            }
          })
          taskIdx++;
        })
        this.$_.forEach(this.eduChart.series, item => {
          item.data = this.$_.map(_result.data, item.id)
        })
        let xaxis = this.$_.map(_result.data, 'educationCourseName');
        let ids = this.$_.map(_result.data, 'eduCourseId');
        let annotations = [];
        this.rate.forEach((r, idx) => {
          // xaxis[idx] += (r ? '(' + r + ')' : '');
          annotations[idx] = {
            x: xaxis[idx],
            id: ids[idx],
            strokeDashArray: 0,
            borderColor: '#EEEEEE',
            borderWidth: 0,
            label: {
              borderColor: '#775DD0',
              orientation: 'horizontal',
              style: {
                color: '#fff',
                fontSize: '12px',
                background: '#775DD0',
              },
              text: r ? r : '',
              // text: r ? '이행률 : ' + r : '',
            }
          }
        })
        xaxis.forEach((x, idx) => {
          if (!annotations[idx]) {
            annotations[idx] = {
              x: x,
              id: '',
              strokeDashArray: 0,
              borderColor: '#EEEEEE',
              borderWidth: 0,
              // borderColor: '#775DD0',
              label: {
                borderColor: '#775DD0',
                orientation: 'horizontal',
                style: {
                  color: '#fff',
                  fontSize: '12px',
                  background: '#775DD0',
                },
                text: '',
              }
            }
          }
        })
        this.eduChart.chartOptions.xaxis.categories = xaxis;
        this.eduChart.chartOptions.annotations.xaxis = annotations;
        
        this.eduChart.chartWidth = '100%';
        this.$refs['eduChart'].refresh();
      },);

    },
    selectedBar(e, chart, opts) {
      this.$http.url = this.$format(this.detailUrl, this.copyYear, opts.w.config.annotations.xaxis[opts.dataPointIndex].id);
      this.$http.type = 'GET';
      this.detailRate = [];
      this.$http.request((_result) => {
        let taskIdx = 0;
        let complete = _result.data.completeList
        let noComplete = _result.data.noCompleteList
        for (let i = 1; i <= 12; i++) {
          if (!this.detailRate[taskIdx]) {
            this.detailRate[taskIdx] = complete['cnt' + i] +  noComplete['cnt' + i] > 0 ?
              String(this.$_.round((complete['cnt' + i] / (complete['cnt' + i] +  noComplete['cnt' + i])) * 100)) + '%' : '';
          }
          taskIdx++;
        }
        this.$_.forEach(this.eduChartDetail.series, item => {
          if (item.id === 'checkCompleteCnt') {
            item.data = [ complete.cnt1, complete.cnt2, complete.cnt3, complete.cnt4, complete.cnt5, complete.cnt6, 
                          complete.cnt7, complete.cnt8, complete.cnt9, complete.cnt10, complete.cnt11, complete.cnt12 ]
          } else {
            item.data = [ noComplete.cnt1, noComplete.cnt2, noComplete.cnt3, noComplete.cnt4, noComplete.cnt5, noComplete.cnt6, 
                          noComplete.cnt7, noComplete.cnt8, noComplete.cnt9, noComplete.cnt10, noComplete.cnt11, noComplete.cnt12 ]
          }
        })
        let xaxis = ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'];
        let annotations = [];
        this.detailRate.forEach((r, idx) => {
          // xaxis[idx] += (r ? '(' + r + ')' : '');
          annotations[idx] = {
            x: xaxis[idx],
            strokeDashArray: 0,
            borderColor: '#EEEEEE',
            borderWidth: 0,
            label: {
              borderColor: '#775DD0',
              orientation: 'horizontal',
              style: {
                color: '#fff',
                fontSize: '12px',
                background: '#775DD0',
              },
              text: r ? r : '',
              // text: r ? '이행률 : ' + r : '',
            }
          }
        })
        xaxis.forEach((x, idx) => {
          if (!annotations[idx]) {
            annotations[idx] = {
              x: x,
              strokeDashArray: 0,
              borderColor: '#EEEEEE',
              borderWidth: 0,
              // borderColor: '#775DD0',
              label: {
                borderColor: '#775DD0',
                orientation: 'horizontal',
                style: {
                  color: '#fff',
                  fontSize: '12px',
                  background: '#775DD0',
                },
                text: '',
              }
            }
          }
        })
        this.eduChartDetail.chartOptions.xaxis.categories = xaxis;
        this.eduChartDetail.chartOptions.annotations.xaxis = annotations;
        this.eduChartDetail.chartOptions.title.text = opts.w.config.annotations.xaxis[opts.dataPointIndex].x + ' ' + this.$comm.getLangLabel('LBL0002967') // ' 월별 현황'
        this.eduChartDetail.chartWidth = '100%';
        this.$refs['eduChartDetail'].refresh();
      })
    }
  }
};
</script>